import React from 'react'
import AnimatedModal from './AnimatedModal'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'

import {RowLayout, ColumnLayout} from '../../components/layout'

const ModalHeading = styled.h1`
  font-family: 'Muli', sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  color: #222;
`

const TextField = styled.input`
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-family: 'Muli', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #666;
`

const RoundButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  border: 0;
  border-radius: 50%;
  background-color: #aaa;
  transition: background-color 150ms linear;
  &:hover {
    background-color: #999;
  }
  &:focus {
    outline: 0;
  }
`

const DidCopyToast = styled.div`
  position: absolute;
  top: -120%;
  right: -8%;
  border-radius: 4px;
  padding: 0.5rem;
  color: #fff;
  background-color: #333;
  opacity: 0;
  animation: fadeInOut 3s;
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    10%,
    90% {
      opacity: 1;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 40%;
    width: 13px;
    height: 13px;
    background-color: #333;
    transform: rotate(45deg);
  }
`

const CopyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fill="#fff"
      d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z"
    />
  </svg>
)

class ShareModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDidCopyToast: false
    }
  }
  handleDidCopyURL = () => {
    if (this.toastTimeoutID) {
      return
    }
    this.toastTimeoutID = setTimeout(
      function() {
        this.setState({ showDidCopyToast: false })
        this.toastTimeoutID = null
      }.bind(this),
      4000
    )
    this.setState({
      showDidCopyToast: true
    })
  }
  render() {
    return (
      <AnimatedModal
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        style={{
          content: {
            width: '20rem',
            // margin: 'auto',
            fontFamily: `'Muli', sans-serif`
          }
        }}
      >
        <ColumnLayout itemSpacing={'1rem'}>
          <ModalHeading>Share ShaqFu Radio</ModalHeading>
          <RowLayout
            justifyContent={'center'}
            itemSpacing={'1rem'}
          >
            <FacebookShareButton
              url={this.props.shareURL}
              style={{ cursor: 'pointer' }}
            >
              <FacebookIcon size={38} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={this.props.shareURL}
              style={{ cursor: 'pointer' }}
            >
              <TwitterIcon size={38} round={true} />
            </TwitterShareButton>
          </RowLayout>
          <RowLayout
            alignItems={'center'}
            justifyContent={'stretch'}
            itemSpacing={'1rem'}
            style={{ position: 'relative' }}
          >
            <TextField type="text" value={this.props.shareURL} readOnly />
            <CopyToClipboard
              text={this.props.shareURL}
              onCopy={this.handleDidCopyURL}
            >
              <RoundButton>{CopyIcon}</RoundButton>
            </CopyToClipboard>
            {this.state.showDidCopyToast && (
              <DidCopyToast>Copied!</DidCopyToast>
            )}
          </RowLayout>
        </ColumnLayout>
      </AnimatedModal>
    )
  }
}

export default ShareModal
