import React from 'react'

import SEO from '../components/SEO'
import PlayerContextConsumer from '../components/state/PlayerContext'
import Footer from '../components/Footer'

import ICPStationPageContent from '../old/pages/ICPStation'

// implemented as a class so lifecycle hooks can fetch dynamic data
export default class ICPStationPage extends React.Component {
  render() {
    if (!this.props.pageContext) {
      console.log('invalid page context:', this.props.pageContext)
      return null
    }
    const { data } = this.props.pageContext
    return (
      <React.Fragment>
        <SEO
          title={`${data.name} - Free Internet Radio - Live365`}
          ignoreTitleTemplate={true}
          description={data.description}
          image={data.fields.logo}
          isRawImageURL={true}
          pathname={data.customURL}
          scripts={[{ src: '//biddr.brealtime.com/92516381-1202.js' }]}
        />
        <PlayerContextConsumer>
          {({ session, updateSession }) => (
            <ICPStationPageContent stationData={data} session={session} />
          )}
        </PlayerContextConsumer>
        <Footer />
      </React.Fragment>
    )
  }
}
