import React from 'react'
import styled from 'styled-components'

import ShareModal from './ShareModal'

const ShareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
      fill="#fff"
    />
  </svg>
)

const RoundButton = styled.button`
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  border: 0;
  border-radius: 50%;
  padding-left: 4px;
  background-color: #1286e9;
  transition: background-color 150ms linear;
  &:hover {
    background-color: #0b78d1;
  }
  &:focus {
    outline: 0;
  }
`

class ShareButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false
    }
  }
  render() {
    return (
      <React.Fragment>
        <ShareModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          shareURL={this.props.shareURL}
        />
        <RoundButton onClick={() => this.setState({ isModalOpen: true })}>
          {ShareIcon}
        </RoundButton>
      </React.Fragment>
    )
  }
}

export default ShareButton
