// third-party
import React from 'react'
import TimeAgo from 'react-timeago'
import { Bling as Ads } from 'react-gpt'
import Image from 'react-image'
import styled from 'styled-components'

// internal
import Header from '../../components/Header'
import ShareButton from '../components/ShareButton'
import { RowLayout, ColumnLayout, ForegroundLayout } from '../../components/layout'
// import LayoutWrapper from '../layout/LayoutWrapper'
import SocialMediaLink from '../../components/SocialMediaLink'
import PlayButton from '../components/PlayButton'
import { getStationDataByID } from '../../networking'
import { LeaderboardAd, LargeRectangleAd, MobileAd } from '../../ads'

class ICPStation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSessionActive: this.isSessionActive(),
      stationData: null,
      showLicensingInfo: false
    }
  }
  componentDidMount() {
    if (!this.state.isSessionActive) {
      this.loadData()
    }
    this.adsRefreshIntervalID = setInterval(function() {
      Ads.refresh()
    }, 30000)
  }
  componentDidUpdate() {
    if (!this.dataRefreshIntervalID && !this.state.isSessionActive) {
      this.dataRefreshIntervalID = setInterval(
        function() {
          this.loadData()
        }.bind(this),
        3000
      )
    } else if (!this.state.isSessionActive && this.isSessionActive()) {
      this.setState({
        isSessionActive: true
      })
      clearInterval(this.dataRefreshIntervalID)
    }
  }
  componentWillUnmount() {
    clearInterval(this.dataRefreshIntervalID)
    clearInterval(this.adsRefreshIntervalID)
  }
  loadData() {
    // console.log('not active session: refreshing station data')
    getStationDataByID(this.props.stationData.mountID, data => {
      this.setState({ stationData: data })
    })
  }
  isSessionActive() {
    if (this.props.session.metadata) {
      return this.props.session.metadata['mount-id'] === this.props.stationData.mountID
    } else {
      return this.props.session.stationID === this.props.stationData.mount_id
    }
  }
  // combine station data from API with custom JSON config
  mergeData() {
    const customData = this.props.stationData
    const sessionData = this.props.session.metadata
    if (sessionData && !this.isSessionActive()) {
      // prevent old session cover image from flashing before new one loads
      delete sessionData.cover
    }
    return Object.assign(
      this.state.stationData || {},
      this.isSessionActive() && sessionData ? sessionData : {},
      customData,
      { theme: 'dark' }
    )
  }
  render() {
    const data = this.mergeData()
    return (
      <PageWrapper theme={data.theme}>
        <Header
          background={{
            image: (data && data['cover']) || undefined
          }}
          lightOnDark={true}
        >
          <RowLayout
            itemSpacing={'4rem'}
            alignItems={'center'}
            justifyContent={'space-between'}
            isResponsive
          >
            <ColumnLayout
              alignItems={'center'}
              itemSpacing={'1rem'}
              style={{ flex: '0' }}
            >
              <StationLogoWrapper>
                {data && (
                  <Image
                    src={data.logoImageURL || data['station-logo']}
                    alt={data.name}
                  />
                )}
              </StationLogoWrapper>
              {data && (
                <RowLayout itemSpacing={'1rem'} justifyContent={'center'}>
                  {data.websiteURL && <SocialMediaLink url={data.websiteURL} />}
                  {data.facebookURL && (
                    <SocialMediaLink url={data.facebookURL} />
                  )}
                  {data.twitterURL && <SocialMediaLink url={data.twitterURL} />}
                  {data.instagramURL && (
                    <SocialMediaLink url={data.instagramURL} />
                  )}
                </RowLayout>
              )}
            </ColumnLayout>
            <StationInfoWrapper style={{ flex: '1' }}>
              <RowLayout
                alignItems={'center'}
                itemSpacing={'2rem'}
                isResponsive
              >
                <ColumnLayout itemSpacing={'1rem'}>
                  {data && (
                    <StationName theme={data.theme}>{data['name']}</StationName>
                  )}
                  {data && (
                    <GenresList>
                      {data.genres &&
                        data.genres.map((genre, index) => (
                          <GenreItem key={index}>{genre}</GenreItem>
                        ))}
                    </GenresList>
                  )}
                </ColumnLayout>
                <ShareButton
                  shareURL={
                    (typeof window !== 'undefined' && window.location.href) ||
                    ''
                  }
                />
              </RowLayout>
              {data &&
                data['current-track'] && (
                  <NowPlaying>
                    <PlayButton
                      stationID={this.props.stationData.mountID}
                      stationURL={this.props.stationData.customURL}
                    />
                    <TrackItem style={{ flex: '1' }}>
                      <ColumnLayout style={{ flex: '1' }}>
                        <p>{data['current-track']['title']}</p>
                        <p>{data['current-track']['artist']}</p>
                      </ColumnLayout>
                      <TrackImage
                        src={data['current-track']['art']}
                        alt={data['current-track']['title']}
                      />
                    </TrackItem>
                  </NowPlaying>
                )}
            </StationInfoWrapper>
          </RowLayout>
        </Header>
        <ContentSection>
          <ContentSectionForeground theme={data.theme}>
            <RowLayout itemSpacing={'2rem'}>
              {typeof window !== 'undefined' &&
                window.innerWidth > 768 && (
                  <ColumnLayout>
                    <LargeRectangleAd />
                  </ColumnLayout>
                )}
              <ColumnLayout itemSpacing={'2.8rem'}>
                {typeof window !== 'undefined' &&
                  window.innerWidth <= 768 && (
                    <MobileAd style={{ alignSelf: 'center' }} />
                  )}
                <ColumnLayout itemSpacing={'1.4rem'}>
                  <Heading theme={data.theme}>Description</Heading>
                  <ColumnLayout itemSpacing={'.6rem'}>
                    {data &&
                      data.description &&
                      data.description
                        .split('\n')
                        .map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                  </ColumnLayout>
                </ColumnLayout>
                {typeof window !== 'undefined' &&
                  window.innerWidth > 1200 && <LeaderboardAd />}
                <ColumnLayout itemSpacing={'1.4rem'}>
                  <Heading theme={data.theme}>Recently Played</Heading>
                  {data && (
                    <RecentlyPlayedList>
                      {data['last-played'] &&
                        data['last-played'].slice(0, 5).map((track, index) => (
                          <RecentlyPlayedItem key={index} theme={data.theme}>
                            <TrackItem>
                              <TrackImage
                                src={track['art']}
                                alt={track['title']}
                              />
                              <ColumnLayout>
                                <TrackTitle>{track['title']}</TrackTitle>
                                <p>{track['artist']}</p>
                              </ColumnLayout>
                              <TrackPlayedTime date={track['start']} />
                            </TrackItem>
                          </RecentlyPlayedItem>
                        ))}
                    </RecentlyPlayedList>
                  )}
                </ColumnLayout>
                {data &&
                  data.artists && (
                    <Heading theme={data.theme}>Artists on {data.name}</Heading>
                  )}
                {data &&
                  data.artists && (
                    <ArtistsList>
                      {data.artists &&
                        data.artists.map((artist, index) => (
                          <div key={index}>
                            <ArtistItem>
                              <ArtistImage
                                src={artist.imageURL}
                                alt={artist.name}
                              />
                              <ArtistTitle>{artist.name}</ArtistTitle>
                            </ArtistItem>
                          </div>
                        ))}
                    </ArtistsList>
                  )}
              </ColumnLayout>
            </RowLayout>
          </ContentSectionForeground>
        </ContentSection>
        <LicensingSection>
          <LicensingSectionForeground>
            <ColumnLayout itemSpacing={'1rem'}>
              <ToggleButton
                onClick={() =>
                  this.setState({
                    showLicensingInfo: !this.state.showLicensingInfo
                  })
                }
              >
                <LicensingInfoHeading>
                  Licensing Information
                </LicensingInfoHeading>
                <ToggleIcon isToggled={this.state.showLicensingInfo} />
              </ToggleButton>
              {this.state.showLicensingInfo && (
                <p>
                  Sound recording and musical works paid through Live365
                  Broadcaster, LLC ("Live365"). Live365 is fully licensed by
                  SoundExchange, ASCAP, BMI, SESAC, RE:Sound, SOCAN, PRS for
                  Music, and PPL.
                </p>
              )}
            </ColumnLayout>
          </LicensingSectionForeground>
        </LicensingSection>
      </PageWrapper>
    )
  }
}

const PageWrapper = styled.div`
  font-family: Muli, sans-serif;
  background-color: ${props => (props.theme === 'dark' ? '#080808' : '#fff')};
`

const StationLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 12rem;
  border-radius: 6px;
  overflow: hidden;
  background-color: #000;
`

const StationInfoWrapper = styled(ColumnLayout).attrs({
  itemSpacing: '1rem'
})`
  @media (max-width: 768px) {
    align-items: center;
  }
`

const StationName = styled.h1`
  font-family: Muli, sans-serif;
  font-size: 2.25rem;
  font-weight: 800;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const GenresList = styled.ul`
  list-style: none;
  font-family: Muli, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  padding: 0;
`

const GenreItem = styled.li`
  display: inline-block;
  background-color: rgba(128, 128, 128, 0.5);
  color: #fff;
  border-radius: 6px;
  padding: 0.2rem 0.4rem 0.3rem;
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const NowPlaying = styled(RowLayout).attrs({
  justifyContent: 'space-between',
  itemSpacing: '2rem'
})`
  text-align: right;
  color: #fff;
  background-color: rgba(128, 128, 128, 0.5);
  padding: 1rem 1.2rem;
  border-radius: 6px;
  overflow: hidden;
`

const ContentSection = styled.div``

const ContentSectionForeground = styled(ForegroundLayout)`
  padding: 2.8rem 0;
  color: ${props => (props.theme === 'dark' ? '#ddd' : '#444')};
  -webkit-font-smoothing: ${props =>
    props.theme === 'dark' ? 'antialiased' : 'initial'};
  -moz-osx-font-smoothing: ${props =>
    props.theme === 'dark' ? 'grayscale' : 'initial'};
`

const RecentlyPlayedList = styled.ul`
  list-style: none;
`

const RecentlyPlayedItem = styled.li`
  padding: 1.5rem 0;
  &:not(:last-child) {
    border-bottom: 2px solid
      ${props => (props.theme === 'dark' ? '#111' : '#eee')};
  }
`

const TrackItem = styled(RowLayout).attrs({
  alignItems: 'center',
  itemSpacing: '2rem'
})`
  overflow: hidden;
  font-size: 1rem;
`

const TrackTitle = styled.p`
  font-weight: 700;
`

const TrackImage = styled(Image)`
  max-width: 72px;
  border-radius: 6px;
  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.1);
`

const TrackPlayedTime = styled(TimeAgo)`
  margin-left: auto;
  color: #aaa;
`

const ArtistsList = styled(RowLayout).attrs({
  isResponsive: true
})`
  // list-style: none;
  flex-wrap: wrap;
  & > div {
    display: inline-block;
    width: 15rem;
    &:not(:last-child) {
      // margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
`

const ArtistItem = styled(RowLayout).attrs({
  alignItems: 'center',
  itemSpacing: '1rem'
})`
  overflow: hidden;
  font-size: 1rem;
`

const ArtistTitle = styled.p``

const ArtistImage = styled(Image)`
  max-width: 48px;
  border-radius: 4px;
  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.1);
`

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  color: ${props => (props.theme === 'dark' ? '#fff' : '#222')};
`

const LicensingSection = styled.div`
  background-color: #ddd;
`

const LicensingSectionForeground = styled(ForegroundLayout)`
  font-size: 14px;
  padding: 1.6rem 0;
  color: #666;
`

const LicensingInfoHeading = styled.h1`
  margin-top: 0;
  font-size: 14px;
  font-weight: 800;
  color: #555;
  user-select: none;
`

const ToggleButton = styled(RowLayout).attrs({
  alignItems: 'center'
})`
  cursor: pointer;
`

function ToggleIcon(props) {
  if (props.isToggled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M7 14l5-5 5 5z" fill="#888" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M7 10l5 5 5-5z" fill="#888" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    )
  }
}

export default ICPStation
